import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  CloseButton,
} from "react-bootstrap";
import CustomImage from "../components/CustomImage";
import Loading from "../components/Loading";
import "./Survey.css";
import { authenticatedFetch } from "../helpFunctions/authFetch";
import { getEnvVariable } from "../helpFunctions/getEnvVariable";

const Survey = () => {
  const [files, setFiles] = useState({ current: [], next: [] });
  const [itemType, setItemType] = useState({}); // 'image' or 'video'
  const [mediaName, setMediaName] = useState({});
  const [caption, setCaption] = useState({ current: "", next: "" }); // Save captions
  const [variantsID, setVariantsId] = useState([]);
  const [alertMessage, setAlertMessage] = useState({});
  const [alertAnimation, setAlertAnimation] = useState(false);
  const [surveyTitle, setSurveyTitle] = useState("");

  const [isSend, setIsSend] = useState(true);
  const [selection, setSelection] = useState(""); // 0, 1, or ''
  const surveyId = useParams().surveyId; // Extract survey ID from URL
  const apiBaseUrl = getEnvVariable("API_BASE_URL");

  const navigate = useNavigate();

  const videoRefs = useRef([]); // Store refs to video elements


  const fetchSurveyTitle = async () => {
    try {
      const response = await authenticatedFetch(navigate, `${apiBaseUrl}SurveyTitle/${surveyId}`);
      const resJson = await response.json();
      console.log("🚀 ~ fetchSurveyTitle ~ resJson:", resJson);
      if (response.ok) {
        setSurveyTitle(resJson.survey_title || "Which is better?");
      } else {
        throw new Error(resJson.message);
      }
    } catch (error) {
      console.error("Error fetching survey title:", error);
      setSurveyTitle("Which is better?");
    }
  };

  useEffect(() => {
    fetchSurveyTitle();
  }, []);

  const fetchImage = async (mode) => {
    setItemType((prevItemType) => ({
      current: prevItemType.next,
      next: prevItemType.next,
    }));
    setMediaName((prevMediaName) => ({
      current: prevMediaName.next,
      next: prevMediaName.next,
    }));
    setFiles((prevFiles) => ({
      current: prevFiles.next,
      next: prevFiles.next,
    }));
    setVariantsId((prevVariants) => ({
      current: prevVariants.next,
      next: prevVariants.next,
    }));
    setCaption((prevCaptions) => ({
      current: prevCaptions.next,
      next: prevCaptions.next,
    }));

    try {
      const response = await authenticatedFetch(
        navigate,
        `${apiBaseUrl}survey/${surveyId}`
      );
      const resJson = await response.json();
      console.log("🚀 ~ fetchImage ~ resJson:", resJson);
      if (!response.ok) {
        throw resJson;
      }
      if (mode === "first") {
        console.log("first");
        setItemType({ current: resJson.file_type });
        setMediaName({ current: resJson.media_name });
        setFiles({ current: resJson.files, next: [] });
        setVariantsId({ current: resJson.variants_id });
        setCaption({ current: resJson.caption || "", next: "" });
      } else {
        if (mode === "vote") {
          console.log("after vote");
          setIsSend(true);
        }
        console.log("next fetch");
        setItemType((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.file_type,
        }));
        setMediaName((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.media_name,
        }));
        setFiles((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.files,
        }));
        setVariantsId((prevFiles) => ({
          current: prevFiles.current,
          next: resJson.variants_id,
        }));
        setCaption((prevCaptions) => ({
          current: prevCaptions.current,
          next: resJson.caption || "",
        }));
        setSelection("");
      }


    } catch (error) {
      if (
        error.detail ===
        "ValueError('The survey must contain at least two active variants')"
      ) {
        setAlertMessage({
          type: "warning",
          header: "Survey Unavailable",
          msg: "This survey requires at least two active variants to proceed.",
        });
      } else {
        setAlertMessage({
          type: "danger",
          header: "Error",
          msg: `There has been a problem: ${error.detail}`,
        });
      }
    }
  };

  useEffect(() => {
    fetchImage("first");
    fetchImage("next");
  }, []);

  const syncVideos = (masterIndex) => {
    const masterVideo = videoRefs.current[masterIndex];

    videoRefs.current.forEach((video, index) => {
      if (index !== masterIndex && video) {
        // Sync time only if the difference is significant (> 0.1s)
        if (Math.abs(video.currentTime - masterVideo.currentTime) > 0.1) {
          video.currentTime = masterVideo.currentTime;
        }
        // Sync play state without interrupting playback
        if (!masterVideo.paused && video.paused) {
          video.play();
        } else if (masterVideo.paused && !video.paused) {
          video.pause();
        }
      }
    });
  };


  // Function to toggle play/pause for all videos
  const togglePlayPause = () => {
    if (!videoRefs.current.length) return;

    const firstVideo = videoRefs.current[0];
    if (firstVideo.paused) {
      videoRefs.current.forEach((video) => video.play());
    } else {
      videoRefs.current.forEach((video) => video.pause());
    }
  };

  // Add keydown event listener for Space key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === "Space") {
        e.preventDefault(); // Prevent scrolling
        togglePlayPause();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const sendVote = async () => {
    const getScreenResolution = () => {
      // Get screen width and height using window object
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      return `${width}x${height}`;
    };

    const response = await authenticatedFetch(navigate, `${apiBaseUrl}vote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey_id: surveyId,
        variant_id_winner: variantsID.current[selection],
        variant_id_loser: variantsID.current[1-selection],
        media: mediaName.current,
        device: /Mobile/.test(navigator.userAgent) ? "Mobile" : "Desktop",
        screen_resolution: getScreenResolution(),
      }),
    });
    if (response.ok) {
      setAlertMessage({
        type: "success",
        header: "Vote Recorded",
        msg: "Your vote has been successfully recorded. Thank you for participating!",
      });
      setTimeout(() => {
        setAlertAnimation(true);
        setTimeout(() => setAlertMessage(""), 500);
        setAlertAnimation(false);
      }, 4000);
    } else {
      const resJson = await response.json();
      setAlertMessage({
        type: "danger",
        header: "Error Recording Vote",
        msg: resJson.message || "Failed to record your vote.",
      });
    }
  };

  // Handler for "Next" button click
  const handleNext = () => {
    setIsSend(false);
    if (selection !== "") sendVote();
    fetchImage("vote");
  };

  return (
    <div className="text-center justify-content-center">
      {/* Conditional rendering based on image loading status */}
      {!files.current.length && !alertMessage.msg ? (
        <Loading />
      ) : (
        <div>
          <Row className="h1 mb-2">
            <header>{surveyTitle}</header>
          </Row>
          <Container className="mb-5 justify-content-center d-flex flex-column flex-md-row">
            {files.current &&
              files.current.map((file, index) => {
                const viewIndex = index + 1;
                const isSelected = selection === index && isSend;
                const borderConfig = "border border-primary border-5";
                return (
                  <Container
                    key={index}
                    className="me-2 pointer-cursor"
                    onClick={(e) => {
                      e.preventDefault();
                      selection === index
                        ? setSelection("")
                        : setSelection(index);
                    }}
                  >
                    {/* Render image or video based on item type */}
                    {itemType.current === "image" && (
                      <CustomImage
                        className={isSelected ? borderConfig : ""}
                        src={files.current[index]}
                        alt={`Item ${viewIndex}`}
                        fluid
                        rounded={2}
                        preload={"auto"}
                      />
                    )}
                    {itemType.current === "video" && (
                      <video
                        ref={(el) => (videoRefs.current[index] = el)} // Attach video ref
                        controls
                        className={isSelected ? borderConfig : ""}
                        preload="auto"
                        autoPlay
                        loop
                        muted
                        src={files.current[index]}
                        type="video/mp4"
                        alt={`Item ${viewIndex}`}
                        onPlay={() => syncVideos(index)}
                        onPause={() => syncVideos(index)}
                        onTimeUpdate={() => syncVideos(index)}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )}
                    <p className="cursor-pointer">Item {viewIndex}</p>
                  </Container>
                );
              })}
          </Container>
          <Row lg={3} xl={4} className="mb-5 justify-content-center">
            {/* Undo button */}
            <Col className="text-start">
              <Button
                variant="secondary"
                onClick={() => setSelection("")}
                disabled={selection === ""}
              >
                Undo
              </Button>
            </Col>
            {/* Next or Skip button based on selection */}
            <Col className="text-end">
              <Button
                variant={selection === "" ? "secondary" : "primary"}
                onClick={isSend ? handleNext : null}
                disabled={
                  !isSend || alertMessage.header === "Survey Unavailable"
                }
              >
                {selection === ""
                  ? isSend
                    ? "Skip"
                    : "Loading..."
                  : isSend
                  ? "Next"
                  : "Sending..."}
              </Button>
            </Col>
          </Row>
          {/* Caption Text Box */}
          {caption.current?.trim() && (
            <Row className="my-4">
              <textarea
                className="form-control text-center mx-auto"
                style={{ maxWidth: "80%" }}
                rows="4"
                value={caption.current}
                readOnly
              ></textarea>
            </Row>
          )}
        </div>
      )}
      <Row sm={3} className="ms-2 position-fixed top-0 start-0 z-1000">
        {alertMessage.msg && (
          <Alert
            variant={alertMessage.type}
            className={`${alertMessage.msg && "alert-animate-enter"} ${
              alertAnimation && "alert-animate-exit"
            }`}
          >
            {alertMessage.header !== "Survey Unavailable" && (
              <div className="d-flex justify-content-end">
                <CloseButton
                  onClick={() => {
                    setAlertAnimation(true);
                    setTimeout(() => setAlertMessage(""), 300);
                    setAlertAnimation(false);
                  }}
                />
              </div>
            )}
            <Alert.Heading className="text-start">
              {alertMessage.header}
            </Alert.Heading>
            <p className="text-start">{alertMessage.msg}</p>
          </Alert>
        )}
      </Row>
    </div>
  );
};

export default Survey;
